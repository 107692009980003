@import 'src/scss/variables';
@import 'src/scss/mixins';

.Draggable {
  position: relative;
  transition: transform 250ms ease;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);

  .draggableButton {
    min-height: 41px;
    appearance: none;
    outline: none;
    border: 0;
    padding: 8px 18px;
    background-color: $color-primary-5;
    border-radius: 5px;
    @include box-shadow(1);
    transform: scale(var(--scale, 1));
    transition: transform 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22),
      box-shadow 300ms ease;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zModal;
  }

  &:not(.handle) {
    .draggableButton {
      touch-action: none;
      cursor: grab;

      &:focus-visible:not(.active &) {
        box-shadow: 0 0 0 3px #4c9ffe;
      }
    }
  }

 

  &.dragging {
    z-index: 1;
    transition: none;

    * {
      cursor: grabbing;
    }

    .draggableButton {
      --scale: 1.06;
      @include box-shadow(1);
      &:focus-visible {
        --box-shadow: 0 0px 10px 2px #4c9ffe;
      }
    }
  }

  &.dragOverlay {
    .draggableButton {
      animation: pop 250ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    }
  }
}

@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow);
  }
}
