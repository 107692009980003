@import 'variables.scss';

//
// Reset
// ----------------------------

* { margin: 0; padding: 0; }
a { color: $color-primary-5; text-decoration: none; }
li { list-style: none; }
svg { outline: none; }
input, textarea { font-size: 1rem; }

// https://css-tricks.com/box-sizing/
html {
  box-sizing: border-box;
  height: 100%;
  font-size: 16px;

  *, *::before, *::after { box-sizing: inherit; }
}

body {
  min-height: 100%;
  color: $black;
  font-family: $primary-font;
}

//
// Global
// ----------------------------

.error { color: $color-danger-5; }

//
// Initial style
// ----------------------------

$fg-color: $black;
$bg-color: $white;
$spinner-fg-color: $black;
$spinner-bg-color: rgba($black, 20%);
$spinner-size: 52px;
$spinner-width: 4px;

#root-loader {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $fg-color;
  background-color: $bg-color;
  transition: opacity 250ms ease;

  > div {
    position: relative;
    width: $spinner-size;
    height: $spinner-size;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-width: $spinner-width;
      border-style: solid;
      border-color: $spinner-bg-color;
      border-top-color: $spinner-fg-color;
      border-radius: 50%;
      transform-origin: center center;
      content: '';

      @keyframes rotate {to { transform: rotate(360deg); }}

      // 'rotate' will be transformed into a unique animation name by CSS modules
      animation: rotate 750ms linear infinite;
    }
  }

  &.-remove {opacity: 0; pointer-events: none;}
}

#revrec-app-root { display: flex; min-height: 100vh; }

// Global styles for toasts
.Toastify__toast--info { background: $color-info-5; }
.Toastify__toast--success { background: $color-success-5; }
.Toastify__toast--warning { background: $color-warning-5; }
.Toastify__toast--error { background: $color-danger-5; }
