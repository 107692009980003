@import 'src/scss/variables';
@import 'src/scss/mixins';

.modal {
  @include box-shadow(5);
  position: absolute;
  max-height: 90vh;
  overflow: auto;

  top: 50%;
  left: 50%;
  min-width: 600px;
  max-width: 100%;
  padding: 32px;
  background-color: $white;
  border-radius: $border-radius;
  outline: none !important;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8001;
  background-color: rgba(0, 0, 0, 0.15);
}

.modalTitle {
  padding-bottom: 16px;
  text-align: center;
}