/* overrides for pdf_viewer.css from PDF.JS web viewer */

.textLayer {
  z-index: 2;
  opacity: 1;
  mix-blend-mode: multiply;
}

.annotationLayer {
  position: absolute;
  top: 0;

  z-index: 3;
}

html
  body
  .textLayer
  > div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(.Highlight-emoji) {
  opacity: 1;
  mix-blend-mode: multiply;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {
    opacity: 0.5;
  }
}

/* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .textLayer {
    opacity: 0.5;
  }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align: auto) {
  .textLayer {
    opacity: 0.5;
  }
}
