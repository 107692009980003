@mixin box-shadow($level) {
  @if $level == 1 {
    box-shadow: 0 0.125em 0.313em rgba(50, 50, 93, 0.09), 0 0.063em 0.125em rgba(0, 0, 0, 0.07);
  } @else if $level == 2 {
    box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09), 0 0.063em 0.938em rgba(0, 0, 0, 0.08);
  } @else if $level == 3 {
    box-shadow: 0 0.063em 0.313em 0 rgba(0, 0, 0, 0.07), 0 0.438em 1.063em 0 rgba(0, 0, 0, 0.1);
  } @else if $level == 4 {
    box-shadow: 0 0.938em 2.188em rgba(50, 50, 93, 0.1), 0 0.313em 0.938em rgba(0, 0, 0, 0.07);
  } @else if $level == 5 {
    box-shadow: 0 0.938em 2.188em rgba(50, 50, 93, 0.15), 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
  }
}

@mixin alert($color) {
  @include box-shadow(1);
  background-color: rgba($color, 0.1);
  color: $black;
  border: 1px solid $color;
  border-radius: 8px;
  display: flex;
  padding: 8px 16px;
  margin: 16px 0 0;
  font-size: 14px;

  > div:first-of-type {
    margin-right: 16px;

    svg {
      height: 1.4em;
      color: $color;
    }
  }

  > div:last-of-type {
    flex: 1;
  }
}
